.fitbit{
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    overflow: hidden !important;
}


.fitbit_Header {
    background-color: black;
    padding: 0px 104px;
}

.homeLeft_Fitbit {
    display: flex;
    flex-direction: column;
    flex: 0.6;
    text-align: left;

}

.homeLeft_Fitbit p{
    margin: 0;
}

.homeLeft_Top_Fitbit p {
    font-family: "Inter";
    font-size: 142px;
    font-weight: 700;
    letter-spacing: -7.1px;
    margin-top: 40px;
}

#homeLeft_Bottom_Fitbit {
    margin-left: 63px;
}

.homeLeft_Bottom_Fitbit p {
    font-size: 142px;
    letter-spacing: -5.5px;
    line-height: 110%;
    padding-left: 68px;
    margin-top: -10px;
    color: white;
    font-weight: bold;
}

.homeLeft_Bottom_Fitbit p:nth-child(1) {
    font-family: "Inter";
    font-weight: 700;
    line-height: 100%;
}

.homeLeft_Bottom_Fitbit p:nth-child(2) {
    font-family: "Fragment";
    font-weight: 200;
    line-height: 90%;
}

.homeLeft_Bottom_Fitbit p:nth-child(3) {
    font-family: "Fragment";
    font-weight: 200;
    line-height: 130%;
    color: #19BAF0;

}

.homeLeft_Top_Fitbit p {
    color: white;
}

.homeLeft_Bottom_Fitbit p {
    color: white;

}

.progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 6px;
    background: #19BAF0;
    transform-origin: 0%;
    border-radius: 200px !important;
  }

  .project_Video {
    z-index: 1;
  }

  .project_Video {
    height: 100vh;
  }

  .project_Video > video{
    width: 100%;
    height: 100vh;
    object-fit: cover;
    opacity: 1;
  }

  .overlay {
    background-color: rgb(0, 0, 0);
    height: 100vh;
    width: 100%;
    position: absolute;
    opacity: 0.2;
  }

  .project_Info {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid white;
    padding: 30px 0px 60px 0px;
  }

  .project_Info, .project_Info_Role, .project_Info_Tools, .project_Info_Teams, .project_Info_ProjectType, .project_Info_ProjectFocus {
    color: white;
    text-align: left;
  }

  .project_Info h5, .project_Info_Role h5, .project_Info_Tools h5, .project_Info_Teams h5, .project_Info_ProjectType h5, .project_Info_ProjectFocus h5 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2.4px;
    margin: 10px 0xp;

  }

  .project_Info p, .project_Info_Role p, .project_Info_Tools p, .project_Info_Teams p, .project_Info_ProjectType p, .project_Info_ProjectFocus p {
    font-size: 15px;
    font-weight: 400;
    margin: 10px 0px;
  }

  /* .project_Video {
    height: 100vh;
    display: flex;
    background-color: black;
  } */

  .project_Video h1 {
    margin: auto;
    color: white;
  }

  .project_Overview {
    padding: 150px 104px;
    display: flex;
    justify-content: space-between;
  }

  .project_Overview_Left {
    flex: 0.4;
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .project_Overview_Right {
    flex: 0.6;
    text-align: left;
  }

  .project_Overview_Left p {
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 1.6px;
  }

  .project_Overview_Left_Link{
    display: flex;
    flex-direction: row;
  }

  .project_Overview_Left_Link p {
    font-size: 18px;
    font-family: Inter;
    font-weight: 500;
    letter-spacing: 1px;
    margin-right: 15px;
    border-bottom: 1px solid black;
  }

  .project_Overview_Left_Link p:hover {
    border-bottom: 1px solid #19BAF0;
    cursor: pointer;
  }


  .project_Overview_Right p {
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.7px;
  }

  .project_Vague_ProblemStatement {
    padding: 121px 176px;
    background: #0029FF;;
  }

  .project_Vague_ProblemStatement h2 {
    text-align: center;
    color: #FFF;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 2.88px;
  }

  .project_Vague_ProblemStatement h2 span{
    font-weight: 300;
}

.project_Vague_ProblemStatement p:nth-child(2){
    color: white;
    width: 665px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin: auto auto 45px auto;
}

.project_Vague_ProblemStatement p:nth-child(3){
    width: 1161px;
    color: #FFF;
    text-align: center;
    font-size: 32px;
    font-family: Inter;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.32px;
    margin: auto;
}

.project_Vague_WhyRedesign p {
    margin: 210px 0px;
    text-align: center;
    font-size: 32px;
    font-family: "Fragment";
    font-weight: 500;
    line-height: normal;
}

.project_Vague_WhyRedesign {
    margin-bottom: 150px;
}

.project_Border{
    border: 0.5px solid #333;
    margin: 120px 104px;
}

.project_Research_Header {
    padding: 0px 104px;
    display: flex;
    justify-content: space-between;
}

.project_Research_Header_Left {
    padding-bottom: 85px;
    flex: 0.3;
    text-align: left;
    border-bottom: 0.5px solid #333;
}

.project_Research_Header_Left_Top {
    height: 265px;
}

.project_Research_Header_Left_Bottom {
    font-size: 20px;
}

.project_Research_Header_Right {
    padding-bottom: 85px;
    flex: 0.6;
    border-bottom: 0.5px solid #333;
}

.project_Research_Header_Right_Top h1 {
    text-align: left;
    font-size: 142px;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -7.1px;
    margin: -50px 0px;
}

.project_Research_Header_Right_Bottom{
    text-align: left;
    margin-top: 85px;
    font-size: 20px;
}

.project_Secondary_Research {
    display: flex;
    flex-direction: column;
    margin: 120px 0px;
    padding: 0px 104px;
}

.project_Secondary_Research_Wrapper {
    display: flex;
    flex-direction: row;
}

.project_Secondary_Research_Wrapper_Left {
    flex: 0.4;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 1.6px;
    text-align: left;
}

.project_Secondary_Research_Wrapper_Right {
    flex: 0.6;
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.7px;
    text-align: left;
}

.project_Secondary_Research img {
    margin-top: 80px;
}

.project_Primary_Research {
    padding: 0px 104px;
}

.project_Primary_Research_Top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.project_Primary_Research_Top_Left, .project_Primary_Research_Bottom_Left{
    flex: 1 !important;
}

.project_Primary_Research_Top_Left h2{
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 1.6px;
    text-align: left;
}

.project_Primary_Research_Top_Left p{
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.7px;
    text-align: left;
}

.project_Primary_Research_Top_right{
    flex: 0.4;
    display: flex;
    flex-direction: column;
}

.project_Primary_Research_Top_Right > h1, .project_Primary_Research_Bottom_Left > h1, .project_Primary_Research_Bottom_Right > h1 {
    color: #0029FF ;
    font-size: 96px;
    font-weight: 700;
    letter-spacing: 0.96px;
    width: 70% ;
    margin: auto 0px auto auto;
}

.project_Primary_Research_Top_Right > p, .project_Primary_Research_Bottom_Left > p, .project_Primary_Research_Bottom_Right > p {
    color: #4D4D4D;
    font-size: 19px;
    font-weight: 400;
    line-height: 140%;
    width: 70%;
    margin: 30px 0px auto auto;
}

.project_Primary_Research_Top_Right > p > span, .project_Primary_Research_Bottom_Left > p > span, .project_Primary_Research_Bottom_Right > p > span {
    font-weight: 600;
    color: black;
    width: 100% !important;
}

.project_Primary_Research_Bottom {
    margin-top: 120px;
}

.project_Primary_Research_Bottom_Left > h1 , .project_Primary_Research_Bottom_Left > p, .project_Primary_Research_Bottom_Left > p > span{
    margin: auto auto auto 0px !important;
    width: 100% !important;
}

.project_Primary_Research_Bottom_Left > p{
    margin-top: 30px !important;
}

.project_Primary_Research_Bottom {
    display: flex;
    justify-content: space-between;
}

.project_BCMIntro {
    text-align: center;
    font-size: 32px;
    font-family:"FragmentReg";
    line-height: 160%;
    padding: 25px 270px;
}

.project_TTMModel{
    padding: 0px 104px;
}

.project_TTMModel p {
    text-align: left;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 1.6px;
    margin-bottom: 40px;
}

.project_Affinity {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 104px;
    text-align: left;
}

.project_Affinity_Left{
    flex: 0.8;
}

.project_Affinity_Left > h1{
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 1.6px;
}

.project_Affinity_Left > p:nth-child(2){
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.7px;
    margin-top: 50px;
}

.project_Affinity_Left > p:nth-child(3){
    font-size: 19px;
    margin-top: 80px;
}

.project_Affinity_Left > p > a {
    color: #FB8842 ;
    text-decoration-color: #FB8842;
}

.project_Design_Header {
    padding: 150px 104px;
}

.project_IA {
    padding: 0px;
    margin: 0px;
}

.project_IA_Wrapper {
    padding: 0px 104px;
}

.project_Wireframes{
    padding: 0px;
    margin: 0px;
}

.project_Wireframes_Wrapper {
    padding: 150px 104px 0px 104px;
}

.project_Wireframes_Text > h2{
    font-size: 48px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -1.44px;
    margin-top: 0px;
}

.project_Testing{
    display: flex;
    text-align: left;
    padding: 120px 104px 0px 104px;
    justify-content: space-between;
}

.project_Testing_Left {
    flex: 0.4;
}

.project_Testing_Left > h2 {
    width: 294px;
    font-size: 32px;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 1.6px;
}

.project_Testing_Left > p {
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.7px;
    margin-top: 40px;
}

.project_Testing_Left > img {
    margin-top: 50px;
}

.project_Testing_Right {
    flex: 0.5;
}

.project_Testing_Right > p {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.92px;
    border-bottom: 0.5px solid #333;
    line-height: 30px;
}

.project_Testing_Right_Results {
    display: flex;
    margin: 40px auto 20px 0px;
    float: left;
}


.project_Testing_Right_Results > img{
    margin: 0px 12px auto auto;
}

.project_Testing_Right_Results > p{
    font-size: 20px;
    font-weight: 400;
    line-height: 140%;
    margin: 0;
}

.project_Final_Solution {
    display: flex;
    flex-direction: column;
}

.project_Final_Solution_Top {
    display: flex;
    justify-content: space-between;
    text-align: left;
    padding: 0px 104px 120px 104px;
}

.project_Final_Solution_Top_Left{
    flex: 0.4;
}

.project_Final_Solution_Top_Right{
    flex: 0.6;
}

.project_Final_Solution_Top_Left > p {
    font-size: 32px;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 1.6px;
}

.project_Final_Solution_Top_Right > p {
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.7px;
}

.project_Final_Solution_Bottom_Top_Left_Left{
    display: flex;
    flex-direction: column;
    flex: 0.4;
}

.project_Final_Solution_Bottom_Top_Left_Left_Flex {
    display: flex;
    flex-direction: row;
    padding-left: 104px;
    text-align: left;
    justify-content: space-between;
}

.project_Final_Solution_Bottom_Top_Left_Left_Flex > h1 {
    font-size: 28px;
    font-weight: 700;
    flex: 0.3;
}

.project_Final_Solution_Bottom_Top_Left_Left_Flex > p{
    font-size: 20px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.6px;
    flex: 0.8;
}

.project_Final_Solution_Bottom_Top_Left {
    display: flex;
    justify-content: space-between;
}

.project_Final_Solution_Bottom_Top_Left_Right {
    background-color: #FDCC76;
    height: 650px;
    width: 730px;
    flex: 0.5;
    display: flex;
}

.project_Final_Solution_Bottom_Top_Left_Right > img {
    height: 550px ;
    border-radius: 45px;
    margin: auto;
}

.project_Final_Solution_Bottom_Top_Left_Left > img {
    height: 100px;
    margin: 0px 0px auto auto;
}

.project_Final_Solution_Bottom_Top_Right {
    display: flex;
    justify-content: space-between;
}

.project_Solution_Goals {
    flex: 0.5;
    margin-top: -150px;
}

.project_Solution_Goals_Text {
    display: flex;
    justify-content: space-between;
    flex: 0.45;
    text-align: left;
    padding-right: 104px;
    margin-top: 35px;
    padding: 0px 30px;
}

.project_Solution_Goals_Text_Top > h2 {
    font-size: 28px;
    font-weight: 700;
}

.project_Solution_Goals_Text_Bottom > p {
    font-size: 20px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.7px;
}

.project_Solution_Goals_Text_Top {
    flex: 0.4;
}

.project_Solution_Goals_Text_Bottom{
    flex: 0.5;
}

 .project_Final_Solution_Bottom_Bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 120px;
    text-align: left;
 }

.project_Final_Solution_Tank_left {
    background-color: #CFEDEB;
    flex: 0.4825;
    height: 650px;
    width: 600px;
    display: flex;
}


.project_Final_Solution_Tank_Right {
    flex: 0.45;
    display: flex;
    justify-content: space-between;
}

.project_Final_Solution_Tank_left > img {
    height: 550px ;
    border-radius: 45px;
    margin: auto;
}

.project_Final_Solution_Tank_Right_left {
    flex: 0.3;
}

.project_Final_Solution_Tank_Right_Right {
    flex: 0.63;
}

.project_Final_Solution_Tank_Right_left > h2 {
    font-size: 28px;
    font-weight: 700;
}

.project_Final_Solution_Tank_Right_left > img {
    margin-top: 200px;
}

.project_Final_Solution_Tank_Right_Right > p {
    font-size: 20px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.7px;
    margin-right: 104px;
}

.motivating_messages {
    flex-direction: row-reverse !important;
    margin-top: 50px;
}

.motivating_messages_right {
    padding-left: 104px;
    padding-right: 0px !important;
    margin-top: 100px;
}

.motivating_messages_right_right {
    flex: 0.6 !important;
}

.motivating_messages_right_right > p {
    margin-left: 60px;
}

.motivating_messages_right_right > img {
    margin-left: 200px;
    margin-top: 30px;
}

.motivating_messages_color {
    background-color: #FFDDCB;
    flex: 0.4825;
    height: 650px;
    width: 600px;
    display: flex;
}

.Setting_goals {
    margin-top: 100px !important;
}

.Setting_goals_image {
    flex: 0.4825;
    height: 650px;
    width: 600px;
}

.Setting_goals_image > img {
    border-radius: 0px;
}

.setting_goals_text {
    margin-top: 100px;
}

.track_progress {
    flex-direction: row-reverse;
    margin-top: -100px;
}

.track_progress_color {
    background: none;
    flex: 0.4825;
    height: 650px;
    width: 600px;
    display: flex;
}

.track_progress_color > img {
    border-radius: 0px;
}

.track_progress_right {
    padding-left: 104px;

    margin: auto !important;
}

.track_progress_right_right {
    flex: 0.7 !important;
}

.track_progress_right_right > p {
    margin-left: 60px;
}

.insight_stories_text {
    margin: auto !important;
}

.insight_stories {
    margin-top: -150px !important;
}

.gamified_levels {
    margin-top: 175px;
}

.gamified_levels_color {
    background-color: #EBEAFE;
    height: 650px;
    width: 730px;
    flex: 0.5;
    display: flex;
}

.reminder {
    background-color: #CFEDEB;
    flex: 0.4825;
    height: 650px;
    width: 600px;
    display: flex;
}

.reminder > img {
    border-radius: 0px;
}

.project_Reflection{
    display: flex;
    flex-direction: column;
}

.project_Reflection_Text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.project_Reflection_Text_Left{
    flex: 0.38;
}

.project_Reflection_Text_Right{
    flex: 0.38;
}

.project_Reflection {
    padding: 0px 104px;
    text-align: left;
}

.project_Reflection_Text_Left > p , .project_Reflection_Text_Right > p{
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.7px;
}

.project_Reflection_Text_Left > h2 , .project_Reflection_Text_Right > h2{
    font-size: 32px;
    font-weight: 600;
    line-height: 130%;
}

.project_Reflection > h2 {
font-size: 32px;
font-weight: 700;
line-height: 140%;
letter-spacing: 1.6px;
margin-bottom: 80px;
margin-top: 120px;
}

@media screen and (max-width: 900px) {
    .fitbit_Header {
        padding: 0px 30px;
    }

    .homeLeft_Fitbit {
        padding-top: 50px;
    }

    .homeLeft_Top_Fitbit p, .homeLeft_Bottom_Fitbit p {
        font-size: 48px;
        letter-spacing: -3px;
        line-height: 125% !important;
        font-weight: 600;
    }    
    .homeLeft_Bottom_Fitbit, .homeLeft_Bottom_Fitbit p {
        margin-left: -18px !important;
    }

    .project_Info {
        display: flex;
        flex-direction: column;
         justify-content: space-between;
         border-top: 0.5px solid white;
         padding: 10px 0px 60px 0px;
    }

    .project_Info_Timeline h5, .project_Info_Role h5, .project_Info_Tools h5, .project_Info_Teams h5, .project_Info_ProjectType h5, .project_Info_ProjectFocus h5 {
        margin: 0px;
    }

    .project_Info_Timeline, .project_Info_Role, .project_Info_Tools, .project_Info_Teams, .project_Info_ProjectType, .project_Info_ProjectFocus {
        margin-bottom: 30px;
    }

    .project_Overview {
        flex-direction: column;
        padding: 100px 30px;
    }

    .project_Overview_Left p {
        padding: 10px 0px 20px 0px;
        margin: 0px;
    }

    .project_Overview_Left_Link p {
        margin: 0px !important;
        padding: 0px !important;
        letter-spacing: 1px;
    }

    .project_Overview_Left_Link {
        margin-bottom: 30px;
    }


    .project_Overview_Left_Link img {
        margin-left: 10px;
    }

    .project_Vague_ProblemStatement{
        padding: 70px 30px;
    }

    .project_Vague_ProblemStatement h2 {
        font-size: 20px !important;
        margin: 0px !important;
    }

    .project_Vague_ProblemStatement p {
        font-size: 16px !important;
        width: 100% !important;
        margin: 50px 0px !important;
    }

    .project_Vague_ProblemStatement p:nth-child(3) {
        font-size: 20px !important;
        font-style: normal;
        width: 90%;
        margin: auto;
    }

    .project_Vague_WhyRedesign p {
        font-size: 24px;
        width: 95%;
        margin: 100px auto;
    }

    .motivating_messages_color {
        height: 500px !important;
    }

    .project_Vague_WhyRedesign > img {
        width: 100%;
        object-fit: contain;
    }

    .project_Research_Header_Right_Top > h1 {
        font-size: 40px;
        margin: 0px;
        letter-spacing: -2px;
    }

    .project_Border {
        width:80%;
        padding: px 30px;
        margin: 80px auto 80px auto;
    }

    .project_Research_Header {
        padding: 0px 30px;
    }

    .project_Overview_Right p{
        font-size: 18px;
        margin-bottom: 100px;
    }

    .project_Overview_Left p{
        font-size: 24px;
    }

    .project_Overview_Left_Link p {
        font-size: 16px;
    }

    .project_Research_Header_Right_Bottom {
        margin-top: 80px;
    }

    .project_Research_Header_Right_Bottom p {
        font-size: 20px !important;
    }

    .project_Research_Header_Left {
        margin-top: -105px;
    }

    .project_Secondary_Research{
        padding: 0px 30px !important;
    }

    .project_Secondary_Research_Wrapper {
        flex-direction: column;
    }

    .project_Secondary_Research img{
        margin-top: 40px;
    }

    .project_Secondary_Research_Wrapper_Left p{
        font-size: 24px;
        padding: 0px;
        margin: 0px;
    }

    .project_Secondary_Research_Wrapper_Right p{
        font-size: 18px;
        font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.7px;
    text-align: left;
    }

    .gamified_levels_color img{
        /* height: 350px !important;
        width: 100% !important; */
        margin: 0 !important;
    }
   

    .project_Primary_Research {
        padding: 0px 30px;
    }

    .project_Primary_Research_Top{
        flex-direction: column;
    }

    .project_Primary_Research_Top_Right h1, .project_Primary_Research_Top_Right p {
        width: 100%;
    }

    .project_Primary_Research_Bottom {
        flex-direction: column;
        margin-top: 80px;
    }

    .project_Primary_Research_Bottom_Right {
        margin-top: 80px;
    }

    .project_Primary_Research_Top_Left h2{
        font-size: 24px;
    }

    .project_Primary_Research_Top_Left p{
        font-size: 18px;
    }
 

    .project_Primary_Research_Bottom_Right h1, .project_Primary_Research_Bottom_Right p {
        width: 100%;
    }

    .project_Primary_Research_Top_Right p, .project_Primary_Research_Bottom_Right p{
        font-size: 16px;
    }

    .project_BCMIntro {
        padding: 0px 30px;
        font-size: 24px;
    }

    .project_TTMModel {
        padding: 0px 30px;
    }

    .project_TTMModel p {
        font-size: 24px;
    }

    .project_TTMModel img {
        width: 100%;
        object-fit: contain;
    }

    .project_Affinity{
        padding: 0px 30px;
        flex-direction: column;
    }

    .project_Affinity_Left h1 {
        font-size: 24px;
    }

    .project_Affinity_Left p:nth-child(2) {
        font-size: 18px !important;
        margin-top: 0px;
    }

    .project_Affinity_Left p:nth-child(3) {
        font-size: 16px !important;
        margin-top: 30px;
    }

    .project_Affinity_Right > img {
        width: 100%;
        object-fit: contain;
    }

    .project_Design_Header {
        margin: 100px 0px;
    }

    .project_IA{
        padding: 0px 30px;
    }

    .project_IA_Wrapper{
        padding: 0px;
    }

    .project_Secondary_Research_Wrapper_Left {
        font-size: 24px;
    }

    .project_Secondary_Research_Wrapper_Right {
        font-size: 18px;
    }

    .project_Wireframes_Wrapper {
        padding: 100px 0px 0px 0px;
    }

    .project_Wireframes_Text > h2 {
        font-size: 28px;
        margin-top: 35px;
    }

    .project_Testing {
        padding: 100px 30px 30px 30px;
    }

    .project_Testing {
        flex-direction: column;
    }

    .project_Testing_Left {
        width: 100%;
    }

    .project_Testing_Left h2 {
        font-size: 24px;
    }

    .project_Testing_Left p {
        font-size: 18px;
        width: 100%;
        margin: 0px;
    }

    .project_Testing_Left img {
        width: 100%;
        object-fit: contain;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .project_Testing_Right_Results p {
        font-size: 18px;
        margin-top: 0px !important;
    }

    .project_Testing_Right_Results img {
        height: 24px;
        margin: 0px;
    }

    .project_Final_Solution_Top {
        flex-direction: column;
        padding: 0px 30px;
    }

    .project_Final_Solution_Top_Left p {
        font-size: 24px;
    }

    .project_Final_Solution_Top_Right p {
        font-size: 18px;
        margin: 0px;
    }

    .project_Final_Solution_Bottom_Top_Left {
        flex-direction: column;
    }

    .project_Final_Solution_Bottom_Top_Left_Left_Flex {
        flex-direction: column;
        margin: 0px;
        padding: 0px 0px !important;
        margin-top: 70px;
    }

    .project_Final_Solution_Bottom_Top_Left_Left_Flex h2{
        margin: 0px;
    }

    .project_Final_Solution_Bottom_Top_Left_Right {
        width: 100%;
        padding: 10% 0%;
    }

    .Setting_goals_image img {
        height: 350px !important;
        width: 100%;
        object-fit: contain;
    }

    .project_Final_Solution_Bottom_Top_Left_Right img {
        height: 350px !important;
        width: 50% !important;
        object-fit: contain;
        border-radius: 42px !important;
    }

    /* .project_Final_Solution_Bottom_Top_Left_Right > img {
        height: 350px !important;
        width: 50%;
        border-radius: 42px !important;
    } */

    .project_Final_Solution_Bottom_Top_Left_Left img {
        display: none;
    }

    .project_Final_Solution_Bottom_Top_Right {
        flex-direction: column-reverse;
        margin-top: 60px;
    }

    .project_Solution_Goals_Text {
        flex-direction: column;
        padding-left: 0px !important;
    }

    .project_Solution_Goals_Text_Top img {
        display: none;
    }

    .project_Solution_Goals_Text_Bottom p {
        margin: 0px;
        width: 100%;
    }

    .project_Solution_Goals {
        margin: 30px 0px 0px 0px;

    }

    .project_Solution_Goals img {
        margin: 0px;
        width: 100%;
        object-fit: contain;
    }


    .project_Final_Solution_Bottom_Bottom {
        flex-direction: column-reverse !important;
    }

    .project_Final_Solution_Tank_Right{
        flex-direction: column;
        padding: 0px 0px !important;
    }

    .project_Final_Solution_Tank_Right_left img {
        display: none;
    }

    .project_Final_Solution_Tank_Right_Right p {
        margin: 0px;
    }

    .project_Final_Solution_Tank_Right {
        margin-bottom: 30px;
    }

    .project_Final_Solution_Tank_left {
        width: 100%;
        /* padding: 10% 0%; */
        margin: auto !important;
        height: 500px !important;
        padding: 30px 0px !important;
    }

    .project_Final_Solution_Tank_left img {
        width: 50% !important;
        border-radius: 40px !important;
        height: 350px !important;
        margin: auto !important;
    }
  

    .project_Final_Solution_Bottom_Bottom,.Setting_goals {
        width: 100%;
    }

    .project_Final_Solution_Bottom_Bottom img,.Setting_goals img {
        width: 100%;
    }



    .motivating_messages {
        display: flex !important;
        flex-direction: column-reverse !important;
    }

    .project_Final_Solution_Tank_Right_left {
        width: 100%;
    }

    .project_Final_Solution_Tank_Right, .motivating_messages_right{
        padding-top: 0px !important;
    }

    .motivating_messages_right {
        padding-left: 0px !important;
    }

    .motivating_messages_right_right > p {
        margin-left: 0px;
    }

    .project_Final_Solution_Tank_Right, .track_progress_right {
        padding-left: 0px !important;
        margin: 0px !important;
    }

    .project_Final_Solution_Bottom_Bottom {
        padding-top: 0px !important;
        margin: 0px !important;
    }

    .project_Final_Solution_Tank_Right_Right >p .track_progress_right_right > p{ 
        margin-left: 0px !important;
        padding-left: 0px !important;
    }

    .Setting_goals {
        margin-top: 0px !important;
    }


}



@media screen and (max-width: 900px) {
    /* General layout adjustments */
    body {
        margin: 0;
        padding: 0;
        font-size: 16px;
        line-height: 1.5;
    }

    .fitbit_Header {
        padding: 0px 15px;
    }

    .homeLeft_Fitbit {
        padding: 20px;
    }

    .homeLeft_Top_Fitbit p, .homeLeft_Bottom_Fitbit p {
        font-size: 42px;
        letter-spacing: -1px;
        line-height: 140% !important;
    }

    .homeLeft_Bottom_Fitbit, .homeLeft_Bottom_Fitbit p {
        margin-left: 0 !important;
        padding: 0 10px;
    }

    .homeLeft_Bottom_Fitbit p:nth-child(3), .homeLeft_Bottom_Fitbit p:nth-child(2){
        line-height: 80%;
    }

    /* Project Info */
    .project_Info {
        flex-direction: column;
        padding: 15px;
    }

    .project_Info h5, .project_Info p {
        font-size: 14px;
        margin: 5px 0;
    }

    /* Project Overview */
    .project_Overview {
        flex-direction: column;
        padding: 20px;
    }

    .project_Overview_Left p {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .project_Overview_Right p {
        font-size: 14px;
        line-height: 1.4;
    }

    /* Problem Statement */
    .project_Vague_ProblemStatement {
        padding: 30px;
        text-align: center;
    }

    .project_Vague_ProblemStatement h2 {
        font-size: 18px;
        margin: 10px 0;
    }

    .project_Vague_ProblemStatement p {
        font-size: 14px;
        margin: 15px 0;
    }

    /* Research Sections */
    .project_Secondary_Research, .project_Primary_Research {
        padding: 20px;
    }

    .project_Secondary_Research_Wrapper, .project_Primary_Research_Top, .project_Primary_Research_Bottom {
        flex-direction: column;
    }

    .project_Secondary_Research_Wrapper_Left, .project_Secondary_Research_Wrapper_Right {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .project_Secondary_Research img, .project_Primary_Research img {
        width: 100%;
        height: auto;
    }

    /* Wireframes */
    .project_Wireframes_Wrapper {
        padding: 20px;
    }

    .project_Wireframes_Text h2 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    /* Final Solution */
    .project_Final_Solution_Top, .project_Final_Solution_Bottom {
        flex-direction: column;
        padding: 20px;
    }

    .project_Final_Solution_Top_Left, .project_Final_Solution_Top_Right {
        margin-bottom: 20px;
    }

    .project_Final_Solution_Bottom_Top_Left, .project_Final_Solution_Bottom_Top_Right {
        flex-direction: column;
        margin: 0 auto;
    }

    .project_Final_Solution img {
        width: 100%;
        height: auto;
        border-radius: 10px;
    }

    /* Reflection Section */
    .project_Reflection {
        padding: 20px;
    }

    .project_Reflection_Text {
        width: 85% !important;
    }

    .project_Reflection_Text_Left, .project_Reflection_Text_Right {
        font-size: 16px;
        line-height: 1.4;
        margin-bottom: 15px;
    }

    .project_Reflection_Text_Left p, .project_Reflection_Text_Right p {
        font-size: 18px !important;
        margin-bottom: 75px;
    }

    .project_Reflection_Text h2 {
        font-size: 18px;
        font-weight: bold;
    }

    /* Motivating Messages */
    .motivating_messages {
        flex-direction: column;
    }

    .motivating_messages_right {
        margin-top: 10px;
        padding: 0;
        padding-left: 0px !important;
    }

    .motivating_messages_right_right img {
        margin: 0 auto;
    }

    .motivating_messages_right

    /* General image and video adjustments */
    img, video {
        /* width: 100%; */
        height: auto;
    }

    /* Buttons and links */
    .project_Overview_Left_Link p {
        font-size: 14px;
        padding: 5px 0;
        margin: 5px 0;
    }

    /* Borders and spacing adjustments */
    .project_Border {
        width: 90%;
        margin: 20px auto;
    }

    /* Font Scaling */
    h1, h2, h3, h4, h5, h6 {
        font-size: calc(100% - 20%);
        line-height: 1.3;
    }

    .reminder, .motivating_messages_color, .track_progress_color, .gamified_levels_color {
        height: 450px;
        width: 100% !important;
        object-fit: contain !important;
        padding: 30px 0px !important;
    }

    .reminder > img {
        height: 400px;
        border-radius: 42px;
        width: 100% !important;
        margin: auto !important;
    }

    .motivating_messages_color img {
        height: 400px;
        border-radius: 42px;
        width: 50% !important;
        margin: auto !important;
    }
}
