/* body {
    --black: #111111;
    --white: #fdfdfd;
    --green: #22cc88;
    --blue: #0055ff;
    --purple: #8855ff;
    --red: #ff0055;
    --orange: #ee4444;
    --yellow: #ffcc00;
    --mustard: #ffaa00;
  
    --background: var(--white);
    --accent: var(--black);
  
    margin: 0;
    padding: 0;
    background-color: var(--background);
    color: var(--accent);
    padding-bottom: 100px;
  } */


.navbar{
    font-family: "Inter";
    display: flex;
    flex-direction: row;
    padding: 10px 104px;
    justify-content: space-between;
    /* top: 0;
    position: sticky; */
    /* backdrop-filter: blur(20px); */
    height: 3rem;
    /* transition-property: all;  */
    transition-timing-function: ease-in;
    transition: 0.5s;
}

/* .progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background: var(--red);
    transform-origin: 0%;
  } */

.navbar.down {
    top: -6rem;
  }

.navbar_Left a {
    color: black;
    text-decoration: none;
}

.navbar_Left p{
    font-size: 14px;;
    font-weight: 700;
    letter-spacing: 2.5px;
}

.navbar_Right {
    display: flex;
    flex-direction: row;
}

.navbar_Right a{
    font-size: 14px;
    text-decoration: none;
    color: #505050;
    font-weight: 500;
    letter-spacing: 1.5px;
    margin: auto auto auto 60px;
    display: inline-block;
    position: relative;
}


.navbar_Right a::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1.5px;
    bottom: 0;
    left: 0;
    background-color: #FB8842;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .navbar_Right a:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

