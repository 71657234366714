.about {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    overflow: hidden !important;
}

.AboutLeft_Top {
    margin-left: 104px;
}

.info {
    padding: 120px 104px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.info > img {
    flex: 0.4;
}

.info_text {
    flex: 0.5;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.info_text > h2 {
    color: #000;
font-family: "Inter";
font-size: 36px;
font-style: normal;
font-weight: 500;
line-height: 46.8px;
letter-spacing: -1.08px;
}

.info_text > p {
    color: #000;
font-family: "Inter";
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 26px;
letter-spacing: -0.6px;
}

.about_title {
    padding: 0px 104px;
    margin-top: 50px;
}

.sketches {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 80px 104px;
}

.sketch{
flex: 0.3;
}

.sketch >img {
    height: 450px;
    width: 100%;
    object-fit: cover;
    border-radius: 0px;
}


@media screen and (max-width: 1000px) {
    .homeLeft_Top_rocket > p{
    font-size: 42px !important;
    font-weight: 600 !important;
    letter-spacing: -3px;
    line-height: 125% !important;
    padding: 30px !important;
}

.info {
    flex-direction: column;
    padding: 30px;
}

.info > img {
    flex: 1 !important;
    width: 100%;
    object-fit: contain;
}

.about_title {
    padding: 0px 30px !important;
}

.sketches {
    flex-direction: column;
    padding: 50px 30px 30px 30px !important;
}

.sketches2 {
    padding: 0px 30px !important;
}

.sketch {
    margin-top: 30px;
}
.homeLeft_Top_rocket p{
    margin-right: 0px !important;
    width: 520%;
    flex: 0.5;
}

.homeLeft_Top_rocket {
    padding: 0px 30px;
}

.sketch2 {
    margin: 0px;
}

}