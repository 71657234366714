/* Default styles for desktop */
.card {
    padding: 24px;
    background-color: #ffffff;
    box-shadow: 8px 8px 30px 0px rgba(0, 0, 0, 0.05);
    width: 550px;
    margin-bottom: 20px; /* Add space between cards */
}

.playgroundAniCards {
    display: flex;
    flex-wrap: wrap; /* Ensure cards wrap to new rows */
    justify-content: space-between; /* Center the cards horizontally */
    gap: 80px; /* Space between cards */
    margin: 200px 104px !important;
}

.cardBottom {
    display: flex;
    align-items: flex-start;
}

.cardBottom p {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.25px;
    text-align: left;
    line-height: 150%;
    margin: 0px 0px 0px 20px;
}

.cardBottom {
    padding: 28px 0px 0px 0px;
}

.cardBottom img {
    height: 28px;
    object-fit: contain;
    margin-top: 3px;
}

.cardTop {
    height: 400px;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    background-color: #CEF9E4;
}

.cardTop img, .cardTop video {
    height: 400px;
    object-fit: contain;
    align-items: flex-end;
}

.cardTop1 {
    background-color: #F3BAB8;
    height: 400px;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
}

.cardTop1 video {
    height: 400px;
    object-fit: contain;
    align-items: flex-end;
}

.playPauseButton {
    position: absolute;
    bottom: 16px;
    right: 16px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 20px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    outline: none !important;
}

.playPauseButton:hover {
    background-color: rgba(0, 0, 0, 0.9);
}

.play, .pause, .replay {
    height: 16px;
    width: 16px;
    outline: none !important;
}

/* Mobile responsiveness */

@media (max-width: 1024px) {
    .card {
        width: 45%; /* Two cards in a row on tablets and small desktops */
    }

    .playgroundAniCards {
        gap: 40px; /* Reduced gap between cards */
        margin: 50px 20px !important; /* Adjust margins for smaller screens */
    }

    .cardTop,
    .cardTop1 {
        height: 300px; /* Reduced height for card top section */
    }

    .cardBottom p {
        font-size: 18px; /* Smaller font size for mobile */
    }

    .cardBottom img {
        height: 24px; /* Smaller images for mobile */
        width: 24px;
    }
}

@media (max-width: 768px) {
    .card {
        width: 100%; /* Full width cards for small mobile screens */
        margin-bottom: 30px; /* More space between cards */
    }

    .playgroundAniCards {
        gap: 20px; /* Even smaller gap on mobile */
        margin: 20px 10px !important; /* Adjust margins */
    }

    .cardTop,
    .cardTop1 {
        height: 250px; /* Further reduced height for card top section */
    }

    .cardTop video{
        height: 250px;
        object-fit: contain;
    }

    .cardBottom p {
        font-size: 16px; /* Further reduced font size for mobile */
    }

    .cardBottom img {
        height: 20px; /* Further reduced image size */
    }

    .playPauseButton {
        width: 30px; /* Smaller play/pause button */
        height: 30px;
        font-size: 18px;
    }

    .play, .pause, .replay {
        height: 14px; /* Smaller icons */
        width: 14px;
    }
}
