/* *{
  cursor: none !important;
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slide-in{
  position: fixed;
  top:0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  transform-origin: bottom;
}

.slide-out{
  position: fixed;
  top:0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  transform-origin: top ;
}

@font-face {
  font-family: "Fragment";
  src: local("Fragment"),
    url("./fonts/Fragment/FragmentSerlifLight.otf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "FragmentReg";
  src: local("FragmentReg"),
    url("./fonts/Fragment/PPFragment-SerifRegularItalic.otf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("./fonts/Inter/interfont.ttf") format("truetype");
}




