.playground_Wrapper {
    display: flex;
    padding: 0px 104px;
    margin-top: 60px;
   
}

.playgroundLeft {
    display: flex;
    flex-direction: column;
    flex: 0.6;
    text-align: left;

}

.playgroundLeft p{
    margin: 0px;
}

.playgroundLeft_Top p {
    font-family: "Inter";
    font-size: 108px;
    font-weight: 600;
    letter-spacing: -5.5px;
}

#playgroundLeft_Bottom {
    margin-left: 63px;
}

.playgroundLeft_Bottom p {
    font-family: "Inter";
    font-size: 108px;
    letter-spacing: -5.5px;
    line-height: 110%;
    padding-left: 68px;
    margin-top: -10px;
}

.playgroundLeft_Bottom p:nth-child(1) {
    font-family: "Fragment";
    font-weight: 200;
    line-height: 120%;
}

/* .playgroundLeft_Bottom p:nth-child(2) {
    font-family: "Fragment";
    font-weight: 200;
    line-height: 120%;
}

.playgroundLeft_Bottom p:nth-child(3) {
    font-family: "Fragment";
    font-weight: 200;
    line-height: 100%;
    color: #FB8842;

} */

.playgroundRight{
    flex: 0.4;
    margin: auto 0px 0px auto;
}

.playgroundRight p {
    text-align: left;
    margin: auto 0px 0px auto;
}

.playgroundRight p a {
    color: black;
}

.playgroundRight p:nth-child(1), .playgroundRight p:nth-child(2){
    font-family: "Inter";
    width: 70%;
    line-height: 140%;
}

/* .playgroundRight p:nth-child(1) {
    margin-bottom: 80px;
} */

.playgroundCards {
    margin-top: 200px;
    padding: 0px 104px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.playgroundCard {
    display: flex;
    flex-direction: column;
    /* width: 591px; */
    padding: 185px 134px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: #FFF;
    margin-bottom: 60px;
    box-shadow: 0px -1px 44px 0px rgba(221, 221, 221, 0.25), 0px 178px 50px 0px rgba(191, 191, 191, 0.00), 0px 114px 46px 0px rgba(191, 191, 191, 0.01), 0px 64px 38px 0px rgba(191, 191, 191, 0.05), 0px 28px 28px 0px rgba(191, 191, 191, 0.09), 0px 7px 16px 0px rgba(191, 191, 191, 0.10);

}


.playgroundCard1 {
    display: flex;
    flex-direction: column;
    height: 600px;
    width: 595px;
    padding-top: 40px;
    /* padding: 185px 134px; */
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: #FFF;
    margin-bottom: 60px;
    box-shadow: 0px -1px 44px 0px rgba(221, 221, 221, 0.25), 0px 178px 50px 0px rgba(191, 191, 191, 0.00), 0px 114px 46px 0px rgba(191, 191, 191, 0.01), 0px 64px 38px 0px rgba(191, 191, 191, 0.05), 0px 28px 28px 0px rgba(191, 191, 191, 0.09), 0px 7px 16px 0px rgba(191, 191, 191, 0.10);
    -webkit-transition: all 1s ease-in-out;
}

.show {
    transition: all 0.2s ease-in-out;
    opacity: 1;
    position: absolute;
    margin-bottom: 240px;
}

.text {
    transition: all 0.5s ease-in-out;
    /* position: absolute; */
    transform: translateY(225px);
}

.hidden {
    transition: all 0.5s ease-in-out 0.5s;
    opacity: 0;
    height: 520px;
    object-fit: contain;
}

.playgroundCard1:hover{
    background-color: #CEF9E4;
    box-shadow: none;
}

 .playgroundCard1:hover .show {
    opacity: 0;
    /* position: static; */
}

.playgroundCard1:hover .text {
    /* position: static !important; */
    transform: translateY(0px);
    font-size: 16px;
    font-weight: 500;
}

.playgroundCard1:hover .hidden {
    opacity: 1;
    height: 520px;
    width: 100%;
    object-fit: contain;
}


.playgroundCard1:not(:hover) .show {
    transition: all 0.5s ease-in-out 0.8s;
    opacity: 1;
}

.playgroundCard1:not(:hover) .hidden {
    transition: all 0.4s ease-in-out 0s;
    opacity: 0;
}

.playgroundCard1:not(:hover) .text {
    /* position: static !important; */
    /* transform: translateY(0px); */
    transition: all 0.5s ease-in-out 0.3s;

}

/* .playgroundCard1:hover{
    box-shadow: none;
    background-color: #CEF9E4;
    font-size: 16px !important;
    
    .show {
        transition: all 1s ease;
        display: none;
       }
       
       .hidden{   
           transform: translateY(20px) !important;
           display: block;

       }
       p{
        font-size: 16px;
       }
} */

.hide {
    height: 500px;
}

.playgroundCard1:nth-child(3){
    font-size: 24px;
    font-weight: 500;
}

.playgroundCard > img {
    margin-bottom: 60px;
}

.playgroundCard > p, .playgroundCard1 > p{
    width: 323px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 36px */
    letter-spacing: -0.24px;
}

.playgroundCardHover {
    /* padding-top: 50px; */
    display: flex;
    flex-direction: column;
    height: 570px;
    width: 640px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: #FFF;
    margin-bottom: 60px;
    box-shadow: 0px -1px 44px 0px rgba(221, 221, 221, 0.25), 0px 178px 50px 0px rgba(191, 191, 191, 0.00), 0px 114px 46px 0px rgba(191, 191, 191, 0.01), 0px 64px 38px 0px rgba(191, 191, 191, 0.05), 0px 28px 28px 0px rgba(191, 191, 191, 0.09), 0px 7px 16px 0px rgba(191, 191, 191, 0.10);
    background-color: #CEF9E4;
    position: relative;

}



.playgroundCardHover > video{
    height: 520px;
}

.hideshow {
    display: flex;
    flex-direction: column;
}




@media screen and (max-width: 1000px) {
    .playground_Wrapper {
        flex-direction: column;
        width: 85%;
        padding: 0px 30px;
    }

    .playgroundLeft_Top > p, .playgroundLeft_Bottom > p{
        font-size: 48px;
        letter-spacing: -3px;
        line-height: 125% !important;
    }

    .playgroundLeft_Bottom p {
        padding-left: 36px;
    }

    .playgroundRight p{
        margin: 0px;
        font-size: 16px;
        margin-top: 20px;
        padding: 0%;
    
    }

    .playgroundRight p:nth-child(1), .playgroundRight p:nth-child(2) {
        margin-bottom: 20px;
        width: 100%;

    }
}