.home {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    overflow: hidden !important;
}

.home_Wrapper {
    display: flex;
    padding: 0px 104px;
    margin-top: 60px;
   
}

.homeLeft {
    display: flex;
    flex-direction: column;
    flex: 0.6;
    text-align: left;

}

.homeLeft p{
    margin: 0px;
}

.homeLeft_Top p {
    font-family: "Inter";
    font-size: 108px;
    font-weight: 600;
    letter-spacing: -5.5px;
}

#homeLeft_Bottom {
    margin-left: 63px;
}

.homeLeft_Bottom p {
    font-family: "Inter";
    font-size: 108px;
    letter-spacing: -5.5px;
    line-height: 110%;
    padding-left: 68px;
    margin-top: -10px;
}

.homeLeft_Bottom p:nth-child(1) {
    font-family: "Inter";
    font-weight: 600;
    line-height: 100%;
}

.homeLeft_Bottom p:nth-child(2) {
    font-family: "Fragment";
    font-weight: 200;
    line-height: 120%;
}

.homeLeft_Bottom p:nth-child(3) {
    font-family: "Fragment";
    font-weight: 200;
    line-height: 100%;
    color: #FB8842;

}

.homeRight{
    flex: 0.4;
    margin: auto 0px 0px auto;
}

.homeRight p {
    text-align: left;
    margin: auto 0px 0px auto;
}

.homeRight p a {
    color: black;
}

.homeRight p:nth-child(1), .homeRight p:nth-child(2){
    font-family: "Inter";
    width: 70%;
    line-height: 140%;
}

.homeRight p:nth-child(1) {
    margin-bottom: 80px;
}

@media screen and (max-width: 1000px) {
    .home_Wrapper {
        flex-direction: column;
        width: 85%;
        padding: 0px 30px;
        margin-top: 50px;
    }

    .homeLeft_Top > p, .homeLeft_Bottom > p{
        font-size: 48px;
        letter-spacing: -3px;
        line-height: 125% !important;
        }

    .homeLeft_Bottom p:nth-child(1) {
        font-weight: 600;
    }

    .homeLeft_Bottom p {
        padding-left: 36px;
    }

    .homeRight p{
        margin: 0px;
        font-size: 16px;
        margin-top: 20px;
        padding: 0%;
    
    }

    .homeRight p:nth-child(1), .homeRight p:nth-child(2) {
        margin-bottom: 20px;
        width: 100%;

    }

    .hide{
        width: 20px;
    }
}
