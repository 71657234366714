.testimonials {
    padding: 0px 104px;
}

.testimonials_title {
    display: flex;
    margin: auto;
    margin-top: 180px;
}

.testimonials_title img {
    margin-left: 200px;
}

.testimonials_Category p {
    color: #000;
    font-size: 40px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0px;
    letter-spacing: -2px;
}

.testimonials_Category p:nth-child(2){
    font-family: "Fragment";
    margin-top: -10px;
    font-weight: 100;
}

.testimonials_Recommendation_Left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    flex: 0.45;
    padding-bottom: 75px;
    border-bottom: 0.5px solid #333;
}

.testimonials_Recommendation_Left_Text > p {
    font-family: "Inter";
}

.testimonials_Recommendation_Left_Designation{
    margin-top: 40px;
}

.testimonials_Recommendation_Left_Designation > p:nth-child(1){
font-family: "Inter";
font-size: 24px;
font-weight: 700;
margin: 0px;
}

.testimonials_Recommendation_Left_Designation > p:nth-child(2){
color: #000;
font-family: "PP Fragment";
font-size: 20px;
font-style: italic;
font-weight: 300;
margin-top: 5px;
}

.testimonials_Recommendation_Left_Designation > p:nth-child(2) > em > span{
    color: #FB8842;
}

.testimonials_Recommendation {
    display: flex;
    flex-direction: row;
    margin-top: 75px;
    justify-content: space-around;
}

.testimonials_Recommendation_Duo{
    flex: 0.45;
    padding-top: 75px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.testimonials_Recommendation_Top > p {
    line-height: 145%;
}

.testimonials_Recommendation_Left_Text > p {
    line-height: 145%;
}

.testimonials_Recommendation_Right{
display: flex;
flex-direction: column;
text-align: left;
flex: 0.45 ;
border-bottom: 0.5px solid #333;
}

.testimonials_Recommendation_Right_right{
    display: flex;
flex-direction: column;
text-align: left;
flex: 0.45 ;
}

@media screen and (max-width: 1000px) {
    .testimonials{
        margin-top: 850px !important;
    }

    .Project_Category2 {
        padding: 0px;
        /* margin-top: 200px; */
    }

}