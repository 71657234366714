.projects {
    margin-top: 180px;
}

.projects_Wrapper {
    padding: 0px 104px;

}

.projects_title {
    display: flex;
    margin: auto;
    margin-top: 180px;
}

.projects_title img {
    margin-left: 10px;
}

.Project_Category p {
    color: #000;
    font-size: 40px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0px;
    letter-spacing: -2px;
}

.Project_Category p:nth-child(2){
    margin-left: 70px;
    font-family: "Fragment";
    margin-top: -10px;
    font-weight: 100 !important;
}

.projects_border {
    height: 100px;
    width: 100%;
}

.project {
    display: flex;
}
.projectDetailsName h2 {
    font-size: 28px;
    font-weight: 600;
    text-align: left;
    line-height: 130%;
    margin: 0px;
}

.projectDetailsName p{
    margin-top: 50px;
    font-size: 18px;
    line-height: 140%;
}

.projectDetailsName img {
    margin-top: 8px;
    height: 18px;
    margin-left: 28px;
}

.projectDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    flex: 5;
    padding-right: 80px;
    text-align: left;
}

.projectImage {
    flex: 0.65;   
}

.projectImage img {
    height: 550px;
    object-fit: contain;
}

.projectDetailsName_Type {
    position: relative;
    bottom: 0;
    display: flex;
    justify-content: space-between;

}

.projects_border2 {
    height: 75px;
    border-bottom: 1px solid#BFBFBF;
    margin-bottom: 75px;
}

.hide{
    /* display: none; */
    opacity: 0;
}

.show{
    opacity: 0 !important;
}

.fitbit_Link, .finergic_Link, .rocket_Link, .iu_Link, .project  {
    text-decoration:none;
    color:black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.projectDetails{
    flex: 0.3;
}



.projectImage {
    display: inline-block;
    overflow: hidden;
    flex: 0.7;
}

.projectImage img {
    display: block;
    transition: transform .4s;   /* smoother zoom */
    margin: auto;
  }
  .projectImage:hover img {
    transform: scale(1.1);
    transform-origin: 50% 50%;
  }

.fitbit_Link:hover, .finergic_Link:hover, .rocket_Link:hover, .iu_Link:hover, .project:hover{
    .hide{
        display: block;
        opacity: 1;
    }

    .show {
        display: none;
        opacity: 1;

}
}

.Project_Category2{
    padding-left: 104px;
    text-align: left;
}

.Project_Category2 >:nth-child(1){
    margin-left: 40px;
}

.Project_Category2 > p > span{
    margin-left: 0px;
}

.recommendation1 {
    display: flex;
    flex-direction: row;
    padding: 0px 104px;
    text-align: left;
    margin-top: 50px;
    justify-content: space-between;
}

.recommendation1_Left{
    display: flex;
    flex-direction: column;
    flex: 0.5;
    justify-content: space-between;
}

.recommendation1_Left > p {
font-family: "Inter";
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 25.2px;
}

.recommendation1_Right {
    /* height: 548px; */
    width: 368px;
    flex: 0.4;
}

.recommendation1_Right > img {
    height: 300px;
    width: 100%;
    object-fit: contain;
}

.title > h2 {
    font-size: 24px;
    font-weight: 600;
    margin: 0px;
}

.title > p {
    font-size: 20px;
    font-family: "Fragment";
    margin-top: 5px;
    font-style: italic;
}

.title > p > span {
    color: #FB8842;
}

.recommendationDuo {
    display: flex;
    margin: 75px 0px;
}

.R2 {
    display: flex;
    flex-direction: column-reverse;
    /* margin: auto; */

}

.R2L {
    /* border-bottom: 1px solid #333333; */
    padding: 40px 0px 75px 0px;
    width: 100%;
    justify-content: space-between;
}

.R2R {
    padding-top: 75px !important;
    border-top: 1px solid #333333;
    width: 100%; 
}

.recommendation1_Right > img {
    height: 300px;
    width: 100%;
    object-fit: contain;
}

.T2 {
    margin-top: 80px !important;
}

.status {
    margin: 0px !important;
    border: 1px solid rgb(226, 226, 226);
    /* width: auto !important; */
    border-radius: 30px;
    background-color: #fcfcfc;
    min-width: 30%;
    max-width: 34%;
    /* box-shadow: 3px 3px 2px 1px rgba(95, 95, 95, 0.1);; */
}

.status2 {
    max-width: 47% !important;
}

.status p {
    margin: auto !important;
    width: auto;
    padding: 10px 30px;
}




@media screen and (max-width: 900px) {

.projects_Wrapper{
    padding: 0px 30px;
}

.Project_Category > p {
    margin-left: -50px;
    font-weight: 600 !important;
}

.status {
    display: none;
}

.project {
    flex-direction: column;
    margin-top: -50px;
}

.projects_title {
    margin-top: -50px;

}

.projectImage{
    height: 250px !important;
}


.projectImage > img {
    width: 100%;
    height: 250px !important;
    object-fit: contain;
}

.projectDetails{
width: 100%;
}

.projects_border {
    height: 50px;
}

.projects_border2 {
    height: 0px;
}

.projectDetailsName p{
    margin-bottom: 25px !important;
    height: auto;
    font-weight: 500;
    font-size: 18px;

}

.projectDetailsName {
    margin-top: 50px;
}

.projectDetailsName > div > h2 {
    margin-bottom: -30px;
    height: auto;
    margin-top: 0px;
} 

.projectDetailsName_Type > p {
    font-size: 16px !important;
    font-weight: 500;
    color: #333333;
}

.projects_border2 {
    margin-top: 30px;
}

.recc {
    margin-top: 150px;
}

.recommendation1 {
    padding: 0px 30px;
    flex-direction: column-reverse;
}


.recommendationDuo {
    flex-direction: column-reverse;
    margin: 0px;
}

.recommendation1_Left > p {
    margin-top: -10px;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 50px;
}

.R2R {
    padding: 0px !important;
    margin: 0px !important;
}

.R2L > p{
    margin-top:-40px
}

.R2L {
    padding-bottom: 0px;
}

.T2 {
    margin-top: 0px !important;
}

.recommendation1_Right {
    width: 100%;
}

.Project_Category > p{
    font-size:  32px;
    font-weight: 600 !important;
}

.status p {
    margin: auto !important;
}

/* .recommendation1_Right > img {
    height: 200px;
    width: 100%;
    object-fit: contain;
} */

}
