.footer {
    margin-top: 75px;
    font-weight: 500;
    font-size: 16px;
    padding: 0px 104px;
}

.footer > p{
    font-size: 14px;
    color: #a2a2a2;
}

.footerwrapper {
    border-top: 1px solid black;
    display: flex;
    flex-direction: row;justify-content: space-between;
    padding-top: 25px;
    margin: auto;
}

.footer_Links {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.footer_Links a {
    margin-top: 15px !important;
    color: black;
    height: 25px;
    font-size: 16px;
    text-decoration: none;
    color: #505050;
    font-weight: 500;
    letter-spacing: 0px;
    margin: auto auto auto 60px;
    display: inline-block;
    position: relative;
}


.footer_Links a::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1.5px;
    bottom: 0;
    left: 0;
    background-color: #FB8842;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .footer_Links a:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }



@media screen and (max-width: 1000px) {
    .footer {
        padding: 0px 30px;
    }
    .footerwrapper {
        display: flex;
        flex-direction: column;
        margin: auto;
    }
    .footer_Links > a {
        text-align: center;
        margin: auto;
    }

    .footer_Links {
        margin-bottom: 15px;
    }
}