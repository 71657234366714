.myNav {
    z-index: 5000 !important;
    top: 0;
    position: sticky;
    transition: transform 0.5s !important;
    background-color: #FB8842;
    backdrop-filter: blur(10px);
  }
  
  .navbard {
    height: 90px;
    padding-top: 10px;
    padding-left: 110px;
    padding-right: 80px;
    display: flex;
    background-color: black;
  }
  
  .navbard_logo {
    flex: 0.7;
    margin: auto;
    text-decoration: none !important;
  }
  
  .navbard_logo > a {
    height: 60px;
    text-align: left;
    color: white;
    text-decoration: none !important;
  }

  .navbard_logo > a > P {
    text-decoration: none;
    letter-spacing: 2px;
    font-family: "Inter";
    font-weight: 600;
    color: white;

  }

  
  .navbar_links_dark {
    flex: 0.3;
    height: 50px;
    display: flex;
    align-items: center;
    color: white;
  }
  
  .navbar_links_dark > a {
    margin-right: 70px;
    color: white;
    font-size: 15px;
    font-family: "Inter";
    font-weight: 500;
    letter-spacing: 3px;
    text-decoration: none;
  }
  
  .navbar_links_dark > a:hover {
    color: black;
  }
  
  @media screen and (max-width: 900px) {
    .myNav {
      display: flex;
    }
    .navbard {
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
    }
    .navbard_logo {
      margin-left: 30px;
      z-index: 1000;
    }
  
    .navbard_logo > a {
      height: 50px;
    }
  
    .navbard_links {
      margin-top: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
  
    .navbard_links > a {
      margin: 40px 0px;
      color: #333;
      font-size: 15px;
      font-family: "Inter";
      font-weight: 500;
      letter-spacing: 3px;
      text-decoration: none;
    }

    .eRGOeu {
        margin-top: 25px !important;
        color: #FB8842;
    }

    .sc-ftLKQv .nIyMW{
      margin-top: 25px;
    }
  }