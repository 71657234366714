.html, body{
    scroll-behavior: smooth !important;
}

.finergic {
    overflow-x: hidden !important;
}

.finergic_Header {
    background-color: black;
    padding: 0px 104px;
}

.homeLeft_finergic {
    display: flex;
    flex-direction: column;
    flex: 0.6;
    text-align: left;

}

.homeLeft_finergic p{
    margin: 0;
}

.homeLeft_Top_finergic p {
    font-family: "Inter";
    font-size: 142px;
    font-weight: 700;
    letter-spacing: -7.1px;
    margin-top: 40px;
    transition: all 2s cubic-bezier(0.165, 0.84, 0.44, 1) ;

}

#homeLeft_Bottom_finergic {
    margin-left: 63px;
}

.homeLeft_Bottom_finergic p {
    font-size: 142px;
    letter-spacing: -5.5px;
    line-height: 110%;
    padding-left: 68px;
    margin-top: -10px;
    color: white;
    font-weight: bold;
}

.homeLeft_Bottom_finergic p:nth-child(1) {
    font-family: "Inter";
    font-weight: 700;
    line-height: 100%;
}

.homeLeft_Bottom_finergic p:nth-child(2) {
    font-family: "Fragment";
    font-weight: 200;
    line-height: 90%;
}

.homeLeft_Bottom_finergic p:nth-child(3) {
    font-family: "Fragment";
    font-weight: 200;
    line-height: 130%;
    color: #19BAF0;

}

.homeLeft_Top_finergic p {
    color: white;
}

.homeLeft_Bottom_finergic p {
    color: white;

}

.progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 6px;
    background: #19BAF0;
    transform-origin: 0%;
    border-radius: 200px !important;
  }

  .project_finergic_Info {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid white;
    padding: 30px 0px 60px 0px;
  }

  .project_finergic_Info, .project_finergic_Info_Role, .project_finergic_Info_Tools, .project_finergic_Info_Teams, .project_finergic_Info_project_finergicType, .project_finergic_Info_project_finergicFocus {
    color: white;
    text-align: left;
    flex: 0.20;
  }

  .project_finergic_Info h5, .project_finergic_Info_Role h5, .project_finergic_Info_Tools h5, .project_finergic_Info_Teams h5, .project_finergic_Info_project_finergicType h5, .project_finergic_Info_project_finergicFocus h5 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2.4px;
    margin: 10px 0xp;

  }

  .project_finergic_Info p, .project_finergic_Info_Role p, .project_finergic_Info_Tools p, .project_finergic_Info_Teams p, .project_finergic_Info_project_finergicType p, .project_finergic_Info_project_finergicFocus p {
    font-size: 15px;
    font-weight: 400;
    margin: 10px 0px;
  }

  .project_Video {
    height: 100vh;
  }

  .project_Video > video{
    width: 100%;
    height: 100vh;
    object-fit: cover;
    opacity: 1;
  }

  .overlay {
    background-color: rgb(0, 0, 0);
    height: 100vh;
    width: 100%;
    position: absolute;
    opacity: 0.2;
  }

  .project_finergic_Video h1 {
    margin: auto;
    color: white;
  }

  .project_finergic_Overview {
    padding: 150px 104px;
    display: flex;
    justify-content: space-between;
  }

  .project_finergic_Overview_Left {
    flex: 0.4;
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .project_finergic_Overview_Right {
    flex: 0.6;
    text-align: left;
  }

  .project_finergic_Overview_Left p {
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 1.6px;
  }

  .project_finergic_Overview_Left_Link{
    display: flex;
    flex-direction: row;
  }

  .project_finergic_Overview_Left_Link p {
    font-size: 18px;
    font-family: Inter;
    font-weight: 500;
    letter-spacing: 1px;
    margin-right: 15px;
    border-bottom: 1px solid black;
  }

  .project_finergic_Overview_Left_Link p:hover {
    border-bottom: 1px solid #19BAF0;
    cursor: pointer;
  }


  .project_finergic_Overview_Right p {
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.4px;
  }

  .project_finergic_Vague_ProblemStatement {
    padding: 121px 176px;
    background-color: #0F2C75;;
  }

  .project_finergic_Vague_ProblemStatement h2 {
    text-align: center;
    color: #FFF;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 2.88px;
  }

  .project_finergic_Vague_ProblemStatement h2 span{
    font-weight: 300;
}

.project_finergic_Vague_ProblemStatement p:nth-child(2){
    color: white;
    width: 665px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin: auto auto 45px auto;
}

.project_finergic_Vague_ProblemStatement p:nth-child(3){
    width: 1161px;
    color: #FFF;
    text-align: center;
    font-size: 32px;
    font-family: Inter;
    font-style: italic;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.32px;
    margin: auto;
}

.project_finergic_Vague_WhyRedesign p {
    margin: 210px 0px;
    text-align: center;
    font-size: 32px;
    font-family: "Fragment";
    font-style: italic;
    font-weight: 500;
    line-height: normal;
}

.project_finergic_Vague_WhyRedesign {
    margin-bottom: 150px;
}

.project_finergic_Border{
    border: 0.5px solid #333;
    margin: 80px 104px 120px 104px;
}

.project_finergic_Research_Header {
    padding: 120px 104px;
    display: flex;
    justify-content: space-between;
}

.project_finergic_Research_Header_Left {
    padding-bottom: 85px;
    flex: 0.3;
    text-align: left;

}

.project_finergic_Research_Header_Left_Top {
    height: 258px;
}

.project_finergic_Research_Header_Left_Top p, .project_finergic_Research_Header_Left_Bottom p {
    margin: 0px;
}

.project_finergic_Research_Header_Right {
    flex: 0.6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.project_finergic_Research_Header_Right_Middle{
    margin: 50px 0px;
    border-bottom: 0.5px solid #333333;
}

.project_finergic_Research_Header_Right_Top, .project_finergic_Research_Header_Right_Bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.project_finergic_Research_Header_Right_Top h1, .project_finergic_Research_Header_Right_Bottom h1 {
    text-align: left;
    font-size: 18px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: 23.4px;
    letter-spacing: -0.4px;
    flex: 0.3;
    margin: 0px;
}

.project_finergic_Research_Header_Right_Top p, .project_finergic_Research_Header_Right_Bottom p {
font-family: Inter;
font-size: 24px;
font-weight: 500;
line-height: 31.2px;
letter-spacing: -0.7px;
text-align: left;
flex: 0.6;
margin: 0px;
}

.project_finergic_Secondary_Research {
    display: flex;
    flex-direction: column;
    margin: 120px 0px;
    margin: auto;
    padding: 120px 0px 0px 0px;
}

.project_finergic_Secondary_Research_Wrapper {
    display: flex;
    flex-direction: row;
    font-size: 142px;
    font-weight: 800;
    letter-spacing: -7.1px;
    justify-content: center;
    margin: auto;
    margin: 0px;
    justify-content: space-between;
}

.project_finergic_Secondary_Research_Wrapper p, .project_finergic_Secondary_Research_Wrapper img{
    transition: all 2s cubic-bezier(0.165, 0.84, 0.44, 1) ;
}

.project_finergic_Secondary_Research_Wrapper p{
    margin: 0px;
}

.project_finergic_Secondary_Research_Wrapper img {
    margin: 0px 40px;
}

.project_finergic_Secondary_Research_Wrapper_Left {
    flex: 0.4;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 1.6px;
    text-align: left;
}

.project_finergic_Secondary_Research_Wrapper_Right {
    flex: 0.6;
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.4px;
    text-align: left;
}

.project_finergic_Secondary_Research img {
    margin: 0px 50px;
}

.project_finergic_Primary_Research {
    padding: 0px;
}

.project_finergic_Primary_Research_Top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.project_finergic_Primary_Research_Top_Left, .project_finergic_Primary_Research_Bottom_Left{
    flex: 0.7 !important;
}

.project_finergic_Primary_Research_Top_Left {
    padding-left: 104px;
}

.project_finergic_Primary_Research_Top_Left h2{
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 1.6px;
    text-align: left;
}

.project_finergic_Primary_Research_Top_Left p{
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.4px;
    text-align: left;
}

.project_finergic_Primary_Research_Top_right{
    flex: 0.4;
    display: flex;
    flex-direction: column;
}

.project_finergic_Primary_Research_Top_Right > h1, .project_finergic_Primary_Research_Bottom_Left > h1, .project_finergic_Primary_Research_Bottom_Right > h1 {
    color: #0029FF ;
    font-size: 96px;
    font-weight: 700;
    letter-spacing: 0.96px;
    width: 70% ;
    margin: auto 0px auto auto;
}

.project_finergic_Primary_Research_Top_Right > p, .project_finergic_Primary_Research_Bottom_Left > p, .project_finergic_Primary_Research_Bottom_Right > p {
    color: #4D4D4D;
    font-size: 19px;
    font-weight: 400;
    line-height: 140%;
    width: 70%;
    margin: 30px 0px auto auto;
}

.project_finergic_Primary_Research_Top_Right > p > span, .project_finergic_Primary_Research_Bottom_Left > p > span, .project_finergic_Primary_Research_Bottom_Right > p > span {
    font-weight: 600;
    color: black;
    width: 100% !important;
}

.project_finergic_Primary_Research_Bottom {
    margin-top: 80px;
}

.project_finergic_Primary_Research_Bottom_Left > h1 , .project_finergic_Primary_Research_Bottom_Left > p, .project_finergic_Primary_Research_Bottom_Left > p > span{
    margin: auto auto auto 0px !important;
    width: 100% !important;
}

.project_finergic_Primary_Research_Bottom_Left > p{
    margin-top: 30px !important;
}

.project_finergic_Primary_Research_Bottom {
    display: flex;
    justify-content: space-between;
}

.project_finergic_ps {
    display: flex;
    padding: 0px 104px;
    justify-content: space-between;

}

.project_finergic_ps_left, .project_finergic_ps_right {
    display: flex;
    flex: 0.4;
    text-align: left;
    justify-content: space-between;
}

.project_finergic_ps_left_left, .project_finergic_ps_left_right, .project_finergic_ps_right_right, .project_finergic_ps_right_left{
    flex: 0.5;
}

.project_finergic_ps_left_left h1, .project_finergic_ps_right_left h1 {
    font-family: Inter;
    font-size: 28px;
    font-weight: 700;
    line-height: 28px; 
    margin: 0px;
}

.project_finergic_ps_left_left p {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
}

.project_finergic_ps_left_right_p1 h1 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 33.6px
}

.border2 {
    border-bottom: 0.5px solid #333333;
    margin: 120px 0px;
}

.project_finergic_ps_left_right_p1 > img {
    height: 40px;
}


.project_finergic_BCMIntro {
    text-align: center;
    font-size: 32px;
    font-family:"FragmentReg";
    line-height: 160%;
    padding: 210px 270px;
}

.project_finergic_TTMModel{
    padding: 0px 104px;
}

.project_finergic_TTMModel p {
    text-align: left;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 1.6px;
    margin-bottom: 40px;
}

.project_finergic_Affinity {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 104px;
    text-align: left;
}

.project_finergic_Affinity_Left{
    flex: 0.8;
}

.project_finergic_Affinity_Left > h1{
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 1.6px;
}

.project_finergic_Affinity_Left > p:nth-child(2){
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.4px;
    margin-top: 50px;
}

.project_finergic_Affinity_Left > p:nth-child(3){
    font-size: 19px;
    margin-top: 80px;
}

.project_finergic_Affinity_Left > p > a {
    color: #FB8842 ;
    text-decoration-color: #FB8842;
}

.project_finergic_Design_Header {
    padding: 150px 104px;
}

.project_finergic_IA {
    padding: 0px;
    margin: 0px;
}

.project_finergic_IA_Wrapper {
    padding: 0px 104px;
}

.project_finergic_Wireframes{
    padding: 0px;
    margin: 0px;
}

.project_finergic_Wireframes_Wrapper {
    padding: 150px 104px 0px 104px;
}

.project_finergic_Wireframes_Text > h2{
    font-size: 48px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -1.44px;
    margin-top: 0px;
}

.project_finergic_Testing{
    display: flex;
    text-align: left;
    padding: 120px 104px 0px 104px;
    justify-content: space-between;
}

.project_finergic_Testing_Left {
    flex: 0.4;
}

.project_finergic_Testing_Left > h2 {
    width: 294px;
    font-size: 32px;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 1.6px;
}

.project_finergic_Testing_Left > p {
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.4px;
    margin-top: 40px;
}

.project_finergic_Testing_Left > img {
    margin-top: 50px;
}

.project_finergic_Testing_Right {
    flex: 0.5;
}

.project_finergic_Testing_Right > p {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.92px;
    border-bottom: 0.5px solid #333;
    line-height: 30px;
}

.project_finergic_Testing_Right_Results {
    display: flex;
    margin: 40px auto 20px 0px;
    float: left;
}


.project_finergic_Testing_Right_Results > img{
    margin: 0px 12px auto auto;
}

.project_finergic_Testing_Right_Results > p{
    font-size: 20px;
    font-weight: 400;
    line-height: 140%;
    margin: 0;
}

.project_finergic_Final_Solution {
    display: flex;
    flex-direction: column;
}

.project_finergic_Final_Solution_Top {
    display: flex;
    justify-content: space-between;
    text-align: left;
    padding: 0px 104px 120px 104px;
}

.project_finergic_Final_Solution_Top_Left{
    flex: 0.4;
}

.project_finergic_Final_Solution_Top_Right{
    flex: 0.6;
}

.project_finergic_Final_Solution_Top_Left > p {
    font-size: 32px;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 1.6px;
}

.project_finergic_Final_Solution_Top_Right > p {
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.4px;
}

.project_finergic_Final_Solution_Bottom_Top_Left_Left{
    display: flex;
    flex-direction: column;
    flex: 0.4;
}

.project_finergic_Final_Solution_Bottom_Top_Left_Left_Flex {
    display: flex;
    flex-direction: row;
    padding-left: 104px;
    text-align: left;
    justify-content: space-between;
}

.project_finergic_Final_Solution_Bottom_Top_Left_Left_Flex > h1 {
    font-size: 28px;
    font-weight: 700;
    flex: 0.3;
}

.project_finergic_Final_Solution_Bottom_Top_Left_Left_Flex > p{
    font-size: 20px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.6px;
    flex: 0.8;
}

.project_finergic_Final_Solution_Bottom_Top_Left {
    display: flex;
    justify-content: space-between;
}

.project_finergic_Final_Solution_Bottom_Top_Left_Right {
    background-color: #FDCC76;
    height: 650px;
    width: 730px;
    flex: 0.5;
    display: flex;
}

.project_finergic_Final_Solution_Bottom_Top_Left_Right > img {
    height: 550px ;
    border-radius: 45px;
    margin: auto;
}

.project_finergic_Final_Solution_Bottom_Top_Left_Left > img {
    height: 100px;
    margin: 0px 0px auto auto;
}

.project_finergic_Final_Solution_Bottom_Top_Right {
    display: flex;
    justify-content: space-between;
}

.project_finergic_Solution_Goals {
    flex: 0.5;
    margin-top: -150px;
}

.project_finergic_Solution_Goals_Text {
    display: flex;
    justify-content: space-between;
    flex: 0.45;
    text-align: left;
    padding-right: 104px;
    margin-top: 35px;
}

.project_finergic_Solution_Goals_Text_Top > h2 {
    font-size: 28px;
    font-weight: 700;
}

.project_finergic_Solution_Goals_Text_Bottom > p {
    font-size: 20px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.4px;
}

.project_finergic_Solution_Goals_Text_Top {
    flex: 0.4;
}

.project_finergic_Solution_Goals_Text_Bottom{
    flex: 0.5;
}

 .project_finergic_Final_Solution_Bottom_Bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 120px;
    text-align: left;
 }

.project_finergic_Final_Solution_Tank_left {
    background-color: #CFEDEB;
    flex: 0.4825;
    height: 650px;
    width: 600px;
    display: flex;
}

.project_finergic_Final_Solution_Tank_Right {
    flex: 0.45;
    display: flex;
    justify-content: space-between;
}

.project_finergic_Final_Solution_Tank_left > img {
    height: 550px ;
    border-radius: 45px;
    margin: auto;
}

.project_finergic_Final_Solution_Tank_Right_left {
    flex: 0.3;
}

.project_finergic_Final_Solution_Tank_Right_Right {
    flex: 0.63;
}

.project_finergic_Final_Solution_Tank_Right_left > h2 {
    font-size: 28px;
    font-weight: 700;
}

.project_finergic_Final_Solution_Tank_Right_left > img {
    margin-top: 200px;
}

.project_finergic_Final_Solution_Tank_Right_Right > p {
    font-size: 20px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.4px;
    margin-right: 104px;
}

.motivating_messages {
    flex-direction: row-reverse !important;
    margin-top: 50px;
}

.motivating_messages_right {
    padding-left: 104px;
    padding-right: 0px !important;
    margin-top: 100px;
}

.motivating_messages_right_right {
    flex: 0.6 !important;
}

.motivating_messages_right_right > p {
    margin-left: 60px;
}

.motivating_messages_right_right > img {
    margin-left: 200px;
    margin-top: 30px;
}

.motivating_messages_color {
    background-color: #FFDDCB;
}

.Setting_goals {
    margin-top: 100px !important;
}

.Setting_goals_image {
    flex: 0.4825;
    height: 650px;
    width: 600px;
}

.Setting_goals_image > img {
    border-radius: 0px;
}

.setting_goals_text {
    margin-top: 100px;
}

.track_progress {
    flex-direction: row-reverse;
    margin-top: -100px;
}

.track_progress_color {
    background: none;
}

.track_progress_color > img {
    border-radius: 0px;
}

.track_progress_right {
    padding-left: 104px;

    margin: auto !important;
}

.track_progress_right_right {
    flex: 0.7 !important;
}

.track_progress_right_right > p {
    margin-left: 60px;
}

.insight_stories_text {
    margin: auto !important;
}

.insight_stories {
    margin-top: -150px !important;
}

.gamified_levels {
    margin-top: 175px;
}

.gamified_levels_color {
    background-color: #EBEAFE;
}

.reminder {
    background-color: #CFEDEB;
}

.reminder > img {
    border-radius: 0px;
}

.project_finergic_Reflection{
    display: flex;
    flex-direction: column;
}

.project_finergic_Reflection_Text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.project_finergic_Reflection_Text_Left{
    flex: 1;
}

.project_finergic_Reflection_Text_Left > ol >li {
    margin-top: 20px;
    background-color: white;
}

.project_finergic_Reflection_Text_Left > h3 {
    font-family: Inter;
    font-size: 32px;
    font-weight: 600;
    line-height: 41.6px;
}

.project_finergic_Reflection {
    padding: 0px 104px;
    text-align: left;
}

.project_finergic_Reflection_Text_Left > p , .project_finergic_Reflection_Text_Right > p{
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.4px;
}

.project_finergic_Reflection_Text_Left > h2 , .project_finergic_Reflection_Text_Right > h2{
    font-size: 32px;
    font-weight: 600;
    line-height: 130%;
}

.project_finergic_Reflection > h2 {
font-size: 32px;
font-weight: 700;
line-height: 140%;
letter-spacing: 1.6px;
margin-bottom: 80px;
margin-top: 120px;
}

body {
    --black: #000000;
    --ash-black: #222;
    --white: #fafafa;
    --sky: #00ccff;
    --green: #22dddd;
    --blue: #1300ff;
    --dusk: #6600ff;
    --purple: #9900ff;
    --pink: #ff0066;
    --red: #fe0222;
    --orange: #fd7702;
    --yellow: #ffbb00;
  
    --accent: var(--white);
    }
  
  #progress {
    margin-top: 20px;
    transform: rotate(-90deg);
    display: flex;
    margin-left: 30px;
  }
  
  circle {
    stroke-dashoffset: 0;
    stroke-width: 15%;
    fill: none;
  }

  .bg {
    stroke: var(--accent);
    opacity: 0.3;
  }
  
  #progress .indicator {
    stroke: var(--accent);
  }
  
  ul {
    display: flex;
    list-style: none;
    height: 450px;
    overflow-x: scroll;
    padding: 20px 0;
    flex: 0 0 600px;
    margin: 0 auto;
  }
  
  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #fff3;
    -webkit-border-radius: 1ex;
  }
  
  ::-webkit-scrollbar-thumb {
    background: var(--accent);
    -webkit-border-radius: 1ex;
  }
  
  ::-webkit-scrollbar-corner {
    background: #fff3;
  }
  
  li {
    flex: 0 0 200px;
    background: var(--accent);
    margin: 0 20px 0 0;
  }
  
  li:last-of-type {
    margin: 0;
  }
  

  .project_carousel{
    background: #0F2C75;
    margin-top: 80px;
    padding-bottom: 30px;
  }

  .old_version {
    margin-top: 120px;
  }

  .old_version_left, .old_version_right{
    border: none;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
  }

  .slider {
    cursor: pointer !important;
    margin-top: 80px;
  }

  .project_Research_Header_Right_Bottom h1{
    font-size: 48px;
    font-weight: 500;
    line-height: 62.4px;
    letter-spacing: -1.44px;
  }

.project_Research_Header_Right_Bottom p{
    font-size: 24px;
    font-weight: 500;
    line-height: 31.2px;
    letter-spacing: -0.72px;
  }

  .homepage {
    padding-top: 0px !important;
  }

  .video {
    padding: 50px;
    margin-top: 80px;
    background: url("../Images/Background1.svg");
  }

  .video2 {
    padding: 50px;
    margin-top: 80px;
    background-color: #EFEFEF;
  }

  .video > video{
    height: 700px;
    width: auto;
    border-radius: 12px;
    border: 1px dashed #ffffff;
  }

  .video2 > video{
    height: 700px;
    width: auto;
    border-radius: 12px;
    border: 1px dashed #000000;
  }

  .project_finergic_Secondary_Research_Wrapper_Right_Right{
    flex: 0.4;
  }

  .project_finergic_Secondary_Research_Wrapper_Right_Right >img {
    height: 700px;
    margin: 0px;
  }

  .makeflex {
    flex: 0.45;
    display: flex;
    flex-direction: column;
  }

  .changeflex1 {
    flex: 0.15 !important;
  }

  .changeflex2 {
    flex: 0.3 !important;
  }

  .project_finergic_Reflection_Text_Left >h3{
    font-family: Inter;
    font-size: 32px;
    font-weight: 700;
    line-height: 33.6px
  }

  .project_finergic_Reflection_Text_Left > ol> li{
    flex: 0.6;
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.4px;
    text-align: left;
  }

  @media screen and (max-width: 1000px) {   
    
    .finergic_Header {
        padding: 0px 30px;
    }

    .homeLeft_finergic {
        padding-top: 50px;
    }

    .homeLeft_Top_finergic p, .homeLeft_Bottom_finergic p {
        font-size: 48px;
        letter-spacing: -3px;
        line-height: 125% !important;
    }    
    .homeLeft_Bottom_finergic, .homeLeft_Bottom_finergic p {
        margin-left: -18px !important;
    }

    .project_finergic_Info {
        display: flex;
        flex-direction: column;
         justify-content: space-between;
         border-top: 0.5px solid white;
         padding: 60px 0px 60px 0px;
    }

    .project_finergic_Info_Timeline h5, .project_finergic_Info_Role h5, .project_finergic_Info_Tools h5, .project_finergic_Info_Teams h5, .project_finergic_Info_ProjectType h5, .project_finergic_Info_ProjectFocus h5 {
        margin: 0px;
    }

    .project_finergic_Info_Timeline, .project_finergic_Info_Role, .project_finergic_Info_Tools, .project_finergic_Info_Teams, .project_finergic_Info_ProjectType, .project_finergic_Info_ProjectFocus {
        margin-bottom: 30px;
    }

    .project_finergic_Overview {
        flex-direction: column;
        padding: 100px 30px;
    }

    .project_finergic_Overview_Left p {
        font-size: 24px;
    }

    .project_finergic_Overview_Left p {
        padding: 10px 0px 20px 0px;
        margin: 0px;
    }

    .project_finergic_Overview_Left_Link p {
        margin: 0px !important;
        padding: 0px !important;
        letter-spacing: 1px;
    }

    .project_finergic_Overview_Left_Link {
        margin-bottom: 30px;
    }

    .project_finergic_Overview_Left_Link > p > a {
        font-size: 18px;
    }


    .project_finergic_Overview_Left_Link img {
        margin-left: 10px;
    }

    .project_finergic_Overview_Right p {
            font-size: 20px ;
            margin-bottom: 50px;
            line-height: 140%;
    }

    .project_Overview_Left_Link img {
        margin-left: 10px;
    }

    .project_finergic_Vague_ProblemStatement{
        padding: 70px 30px;
    }

    .project_finergic_Vague_ProblemStatement h2 {
        font-size: 20px !important;
        margin: 0px !important;
    }

    .project_finergic_Vague_ProblemStatement p {
        font-size: 16px !important;
        width: 100% !important;
        margin: 50px 0px !important;
    }

    .project_finergic_Vague_ProblemStatement p:nth-child(3) {
        font-size: 20px !important;
        font-style: normal;
        width: 90%;
        margin: auto;
    }

    .project_finergic_Research_Header {
        padding: 100px 30px;
    }

    .project_finergic_Research_Header_Left {
        flex: 0.1 ;
    }

    .project_finergic_Research_Header_Left_Bottom {
        margin-top: 215px;
    }

    .project_finergic_Research_Header_Right {
        flex: 1;
    }

    .project_finergic_Research_Header_Right_Top > p, .project_finergic_Research_Header_Right_Bottom p {
        font-size: 18px !important;
    }

    .project_finergic_Secondary_Research_Wrapper p {
        font-size: 92px;
        letter-spacing: 0px;
    }

    .project_finergic_ps {
        flex-direction: column;
    }

    .project_finergic_ps {
        padding: 0px 30px;
    }

    .project_finergic_ps_left {
        margin-bottom: 100px;
    }

    .project_finergic_ps_left_left h1, .project_finergic_ps_right_left h1 {
        font-size: 24px;
    }

    .project_finergic_ps_left_right_p1 {
        flex-direction: row;
    }

    .project_finergic_ps_left_right_p1 h1 {
        font-size: 20px;
        line-height: 130%;
    }

    .border2 {
        margin: 50px 0px;
    }

    .project_finergic_Border {
        margin: 100px 30px;
    }

    .project_finergic_Secondary_Research, .project_IA {
        padding: 50px 30px;
    }

    .project_finergic_Secondary_Research_Wrapper_Left p {
        font-size: 24px;
        margin: 0;
        padding: 0;
    }

    .project_finergic_Secondary_Research_Wrapper_Right p {
        font-size: 18px;
        line-height: 140%;
        margin: 18px 0px;
    }

    .project_finergic_Secondary_Research_Wrapper_Left, .project_finergic_Secondary_Research_Wrapper_Right {
        flex: 1;
    }

    .project_carousel{
        height: auto;
        width: 100%;
        overflow-y: hidden !important;
    }

    #progress{
        height: 50px;
        width: 50px;
    }

    .project_finergic_Secondary_Research img {
        width: 150%;
    }

    ul{
        height: 350px;
    }

    .project_finergic_Primary_Research_Top_Left{
        padding:0px 30px;
    }

    .project_finergic_Primary_Research_Top {
        flex-direction: column;
    }

    .project_finergic_Primary_Research_Top_Left h2 {
        font-size: 24px;
    }

    .project_finergic_Primary_Research_Top_Left p {
        font-size: 18px;
    }

    .project_finergic_Primary_Research_Top_Right img {
        height: 250px;
    }

    .project_finergic_Primary_Research_Bottom {
        flex-direction: column;
        margin-top: 30px;
    }

    .project_finergic_Primary_Research_Bottom_Left img {
        height: 250px;
        margin-bottom: 30px;
    }

    .project_finergic_Primary_Research_Bottom_Right img {
        height: 250px;
    }

    .project_finergic_BCMIntro{
        padding: 100px 30px;
    }

    .project_finergic_BCMIntro p {
        font-size: 24px;
        line-height: 140%;
    }

    .project_finergic_Brandkit img {
        height: 200px;
    }

    .project_Secondary_Research_Wrapper_Left p {
        font-size: 24px;
    }

    .project_Research_Header_Right_Bottom h1 {
        font-size: 24px;
        line-height: 140%;
        letter-spacing: -1px;
    }

    .project_finergic_Secondary_Research_Wrapper, .project_IA_Wrapper {
        flex-direction: row;
    }

    .project_finergic_Secondary_Research, .homepage{
        padding-bottom: 0px;
    }

    .video video, .video2 video {
        height: 100%;
        width: 100%;
    }

    .video, .video2 {
        padding: 10px;
        margin-top: 30px;
    }

    .project_finergic_Border {
        margin: 50px 30px;
    }

    .project_finergic_Secondary_Research_Wrapper_Right_Right img {
        height: 300px;
        width: 100%;
        object-fit: contain;
    }

    .project_finergic_Reflection {
        padding: 80px 30px 30px 30px;
    }


    .project_finergic_Reflection h2 {
        font-size: 24px;
    }

    .project_finergic_Reflection_Text_Left h3 {
        font-size: 20px;
        line-height: 140%;
        letter-spacing: -0.75px;
    }

    .project_finergic_Reflection_Text_Left > ol > li {
        font-size: 18px;
    }










    .motivating_messages {
        flex-direction: column-reverse !important;
    }

    .motivating_messages_right_right > p{
        margin: 0px;
    }

    .motivating_messages_right_right > img {
        display: none;
    }

    .setting_goals_text{
        margin-top: 30px ;
    }  

    .Setting_goals_image {
        width: 100% !important;
        object-fit: contain !important;
    }

    .project_Final_Solution_Tank_Right .track_progress_right{
        padding-left: 0px !important;
    }

    .project_Final_Solution_Tank_Right{
        margin-bottom: 30px !important;
    }

    .track_progress_right_right > p{ 
        margin-left: 0px !important;
        padding-left: 0px !important;
    }

    .Setting_goals {
        margin-top: 0px !important;
    }
}
