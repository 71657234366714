#password {

}

.my_form {
    /* height: 100vh !important; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.form {
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 25%;

}

.form input {
    height: 45px;
    font-size: 20px;
    padding: 0px 10px;
    border: 1.5px solid black;

}

.my_form_Image {
    height: 70px;
    width: 100%;
    margin: 90px 0px;
}

.form button{
    margin-top: 30px;
    height: 45px;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    border: 0.5px solid #333;
    background-color: #fff;
    margin-bottom: 100px;

    
}

.form button:hover{
    color: #fff;
    background-color: #FB8842;
    cursor: pointer ;
    border: none;
}

@media screen and (max-width: 1000px) {
    .form{
        /* padding: 50px; */
        width: 70%;
    }
}