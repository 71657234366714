.myNav {
    z-index: 5000 !important;
    top: 0;
    position: sticky;
    transition: transform 0.5s !important;
    background-color: #FB8842;
    backdrop-filter: blur(10px);
  }
  
  .navbar {
    height: 90px;
    padding-top: 10px;
    padding-left: 110px;
    padding-right: 80px;
    display: flex;
    justify-content: space-around !important;
  }

  .sc-fLsdDA .ctksag{
    width: 1000px !important;
  }

  .fwhNTK {
    flex: 0 !important;
  }
  
  .navbar_logo {
    flex: 0.72;
    margin: auto;
    text-decoration: none !important;
  }

  .navbar_logo.active{
    margin: auto 0px !important;
  }
  
  .navbar_logo > a {
    height: 60px;
    text-align: left;
    color: black;
    text-decoration: none !important;
  }

  .navbar_logo > a > p {
    text-decoration: none;
    letter-spacing: 2px;
    font-family: "Inter";
    font-weight: 700;
    font-size: 14px;

  }
  
  .navbar_links {
    flex: 0.8;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .navbar_links > a {
    /* margin-right: 70px; */
    color: #333;
    font-size: 15px;
    font-family: "Inter";
    font-weight: 500;
    letter-spacing: 3px;
    text-decoration: none;
  }
  
  .navbar_links > a:hover {
    color: black;
  }
  
  @media screen and (max-width: 900px) {
    .myNav {
      display: flex;
    }
    .navbar {
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
    }
    .navbar_logo {
      margin-left: 30px;
      z-index: 1000;
    }
  
    .navbar_logo > a {
      height: 50px;
    }

    .navbar_logo > a > p {
      font-weight: 600;
    }
  
    .navbar_links {
      margin-top: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
  
    .navbar_links > a {
      margin: 40px 0px;
      color: #333;
      font-size: 15px;
      font-family: "Inter";
      font-weight: 500;
      letter-spacing: 3px;
      text-decoration: none;
    }

    .eRGOeu {
        margin-top: 25px !important;
        color: #FB8842;
        margin-right: 0px !important;
    }

    .sc-ftLKQv, .nIyMW{
      margin-top: 25px;
      margin-right: 0px !important;
    }

    .RtkRg, .kgZHLI{
      margin-right: 0px !important;
    }
  }
